<template>
  <i-modal
    :title="$t('editing', { value: $tc('password', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0">
      <v-col v-if="!not_me" cols="12">
        <ValidationProvider
          vid="old_password"
          :name="$tc('password', 1)"
          :rules="{
            required: !not_me
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            outlined
            :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
            :error-messages="errors[0]"
            :label="$tc('old_password', 1)"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            v-model="passwordForm.old_password"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="pw_confirm"
          rules="required|min:8"
          :name="$tc('password', 1)"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            outlined
            ref="pw_confirm"
            :append-icon="showPass2 ? 'fa-eye' : 'fa-eye-slash'"
            :data-vv-as="$tc('password', 1)"
            :error-messages="errors[0]"
            :label="$tc('new_password', 1)"
            :type="showPass2 ? 'text' : 'password'"
            @click:append="showPass2 = !showPass2"
            v-model="passwordForm.password"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="12">
        <ValidationProvider
          vid="confirm_field"
          :name="$tc('password', 2)"
          :rules="{
            required: true,
            confirmed: 'pw_confirm'
          }"
          v-slot="{ errors }"
        >
          <v-text-field
            color="secondary"
            name="confirm_field"
            outlined
            :append-icon="showPass3 ? 'fa-eye' : 'fa-eye-slash'"
            :error-messages="errors[0]"
            :label="$tc('password', 2)"
            :type="showPass3 ? 'text' : 'password'"
            @click:append="showPass3 = !showPass3"
            v-model="passwordForm.password2"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  name: 'update-password',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: [Object],
      default: () => null
    },
    me: {
      type: [Object],
      default: () => null
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      edit: false,
      passwordForm: {
        old_password: '',
        password: '',
        password2: ''
      },
      showPass: false,
      showPass2: false,
      showPass3: false,
      can_change: false,
      dialog: false,
      load: false,
      user_changed: {}
    }
  },
  computed: {
    /**
     * not_me
     * Método para resetear todos los datos del formulario en caso de crear
     * un usuario nuevo.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    not_me() {
      if (this.me.id != this.user.id || this.isAdmin) {
        return true
      }
      return false
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (this.not_me) {
            this.user_changed = {
              password: this.passwordForm.password,
              profile: {
                phone: this.user.profile.phone,
                city: this.user.profile.address1,
                state: this.user.profile.city,
                address1: this.user.profile.state,
                zip_code: this.user.profile.zip_code
              }
            }
            await this.$api.user.edit({
              pk: this.user.id,
              form: this.user_changed
            })
          } else {
            await this.$api.user.changePassword({
              form: this.passwordForm
            })
          }
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('password', 1)} ${this.$tc('update', 3)}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
