<route>
{
  "meta": {
    "permission": [
      "auth.add_user",
      "auth.change_user"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form
          enctype="multipart/form-data"
          @submit.prevent="handleSubmit(submit)"
        >
          <i-toolbar
            dontSave
            :title="
              user.id
                ? `${$t('edit')} ${$tc('user', 1)}`
                : `${$t('add', { value: $tc('user', 1) })}`
            "
            :loading="load"
          >
            <template v-slot:submit="{ item }">
              <i-btn
                v-if="validation !== 'create' && isAdmin"
                class="mx-1"
                color="info"
                icon="fa-unlock-alt"
                outlined
                :title="$tc('password', 1)"
                @click="open()"
              />
              <i-btn
                v-if="!creationOrEditing && viewUserBtn.editUser"
                class="mx-1"
                color="text-dark"
                icon="fa-edit"
                outlined
                :title="$t('edit')"
                @click="activateEdit(item)"
              />
              <i-btn
                v-if="creationOrEditing && viewUserBtn.editUser"
                color="text-dark"
                icon="fa-save"
                outlined
                :loading="isLoading2"
                :small="$vuetify.breakpoint.smAndDown"
                :title="$tc('save', 1)"
                @click="submit"
              />
            </template>
          </i-toolbar>
          <v-card-text>
            <v-row class="align-items-center pb-3">
              <v-col cols="12" md="4">
                <i-image
                  :file.sync="user.profile.photo"
                  :readonly="!creationOrEditing"
                  v-model="imageUrl"
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <!-- Estatus -->
                  <v-col cols="12">
                    <h6>{{ $tc('status') }}</h6>
                    <ValidationProvider
                      vid="active"
                      rules="required"
                      :name="$t('active')"
                      v-slot="{ errors }"
                    >
                      <v-switch
                        class="v-switch mt-0"
                        color="primary"
                        validate-on-blur
                        :disabled="!creationOrEditing"
                        :label="$t('active')"
                        :error-messages="errors[0]"
                        key="active-input"
                        v-model="user.is_active"
                      ></v-switch>
                    </ValidationProvider>
                  </v-col>

                  <!-- Username -->
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      vid="username"
                      rules="required"
                      :name="$tc('username', 1)"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        color="secondary"
                        outlined
                        :disabled="validation !== 'create'"
                        :error-messages="errors[0]"
                        :label="$tc('username', 1)"
                        v-model="user.username"
                      />
                    </ValidationProvider>
                  </v-col>

                  <!-- Correo -->
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      vid="email"
                      rules="required|email"
                      :name="$tc('email', 1)"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        color="secondary"
                        outlined
                        :disabled="validation !== 'create'"
                        :error-messages="errors[0]"
                        :label="$tc('email', 1)"
                        v-model="user.email"
                      />
                    </ValidationProvider>
                  </v-col>

                  <!-- Nombre -->
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      vid="first_name"
                      rules="required"
                      :name="$t('first_name')"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        color="secondary"
                        outlined
                        :disabled="!creationOrEditing"
                        :error-messages="errors[0]"
                        :label="$t('first_name')"
                        v-model="user.first_name"
                      />
                    </ValidationProvider>
                  </v-col>

                  <!-- Apellido -->
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      vid="last_name"
                      :name="$t('last_name')"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        name="last_name"
                        outlined
                        :disabled="!creationOrEditing"
                        validate-on-blur
                        :error-messages="errors[0]"
                        v-model="user.last_name"
                        color="secondary"
                        :label="$t('last_name')"
                        class="secondary--text"
                        key="last_name-input"
                      />
                    </ValidationProvider>
                  </v-col>

                  <!-- Número de teléfono -->
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      vid="phone_number"
                      :name="$t('phone_number')"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        color="secondary"
                        counter
                        maxlength="14"
                        outlined
                        persistent-hint
                        :disabled="!creationOrEditing"
                        :error-messages="errors[0]"
                        :label="$t('phone_number')"
                        v-model="user.profile.phone"
                      />
                    </ValidationProvider>
                  </v-col>

                  <!-- Identificación -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      color="secondary"
                      outlined
                      persistent-hint
                      :hint="$t('optional_field')"
                      :disabled="!creationOrEditing"
                      :label="$t('identification', 1)"
                      v-model="user.profile.identification"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <!-- Dirección -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address"
                  rules="required"
                  :name="$t('address')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="!creationOrEditing"
                    :error-messages="errors[0]"
                    :label="$t('address')"
                    v-model="user.profile.address1"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Dirección 2 -->
              <v-col cols="12" md="4">
                <v-text-field
                  color="secondary"
                  outlined
                  :disabled="!creationOrEditing"
                  :label="$t('address2')"
                  v-model="user.profile.address2"
                />
              </v-col>

              <!-- Ciudad -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="city"
                  rules="required"
                  :name="$t('city')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="secondary"
                    outlined
                    :disabled="!creationOrEditing"
                    :error-messages="errors[0]"
                    :label="$t('city')"
                    v-model="user.profile.city"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Estado -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="state"
                  rules="required"
                  :name="$t('state')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="secondary"
                    outlined
                    :disabled="!creationOrEditing"
                    :error-messages="errors[0]"
                    :label="$t('state')"
                    v-model="user.profile.state"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Código postal -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="zip_code"
                  rules="required|numeric"
                  :name="$t('zip_code')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="secondary"
                    outlined
                    maxlength="12"
                    :disabled="!creationOrEditing"
                    :error-messages="errors[0]"
                    :label="$t('zip_code')"
                    v-model="user.profile.zip_code"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Web -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="web_url"
                  rules="url"
                  :name="$t('web_url')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    color="secondary"
                    outlined
                    :disabled="!creationOrEditing"
                    :error-messages="errors[0]"
                    :label="$t('web_url')"
                    v-model="user.profile.web"
                  />
                </ValidationProvider>
              </v-col>

              <!-- Rol -->
              <v-col v-if="viewUserBtn.viewRol" cols="12" md="6">
                <dialRole
                  v-model="dialogRole"
                  :to-edit="toEditRole"
                  @done="getRoles"
                />
                <v-autocomplete
                  autocomplete="off"
                  chips
                  color="secondary"
                  clearable
                  item-text="name"
                  item-value="pk"
                  outlined
                  multiple
                  return-object
                  key="role-input"
                  :disabled="
                    !creationOrEditing || !viewUserBtn.editRol || roleParam
                  "
                  :items="roles"
                  :label="$tc('roles', 1)"
                  :loading="isLoading"
                  :prepend-inner-icon="
                    !roleParam && viewUserBtn.addRol ? 'fa-plus' : ''
                  "
                  @click:prepend-inner="openRoleModal()"
                  v-model="user.groups"
                ></v-autocomplete>
              </v-col>

              <!-- Permisos -->
              <v-col v-if="viewUserBtn.viewPermission" cols="12" md="6">
                <v-select
                  class="py-0"
                  chips
                  item-text="fields.name"
                  item-value="pk"
                  multiple
                  outlined
                  persistent-hint
                  return-object
                  :disabled="
                    !creationOrEditing ||
                    !viewUserBtn.editPermission ||
                    !viewUserBtn.editUser
                  "
                  :hint="$t('what_permissions')"
                  :items="permissions_list"
                  :label="$t('permissions_select')"
                  v-model="user.user_permissions"
                ></v-select>
              </v-col>

              <!-- Contraseña -->
              <v-col v-if="validation === 'create'" cols="12" md="6">
                <ValidationProvider
                  vid="pw_confirm"
                  rules="required|min:8"
                  :name="$tc('password', 1)"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="new-password"
                    color="secondary"
                    outlined
                    ref="pw_confirm"
                    :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
                    :error-messages="errors[0]"
                    :label="$tc('password', 1)"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    v-model="user.password"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Confirmar Contraseña -->
              <v-col v-if="validation === 'create'" cols="12" md="6">
                <ValidationProvider
                  vid="confirm_field"
                  rules="required|confirmed:pw_confirm"
                  :name="$tc('password', 2)"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    autocomplete="new-password"
                    color="secondary"
                    outlined
                    :append-icon="showPass2 ? 'fa-eye' : 'fa-eye-slash'"
                    :error-messages="errors[0]"
                    :label="$tc('password', 2)"
                    :type="showPass2 ? 'text' : 'password'"
                    @click:append="showPass2 = !showPass2"
                    v-model="password2"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <update-password
              ref="password_modal"
              :isAdmin="isAdmin"
              :me="me"
              :user="user"
              @done="reload = true"
              v-model="dialog"
            />
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import updatePassword from '../../components/user/dialPassword.vue'
import dialRole from '../../components/roles/modal.vue'
export default {
  props: {
    roleParam: {
      type: Boolean,
      default: false
    }
  },
  components: {
    updatePassword,
    dialRole
  },
  data() {
    return {
      validation: 'create',
      user: {
        id: '',
        is_active: true,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        role_id: this.roleParam,
        groups: [],
        user_permissions: [],
        profile: {
          photo: '',
          phone: '',
          web: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: '',
          token_forgot: null,
          identification: ''
        }
      },
      imageUrl: null,
      showPass: false,
      showPass2: false,
      password2: '',
      roles: [],
      role: null,
      permissions: '',
      permissions_list: [],
      edit_modal: false,
      load: false,
      edit: false,
      create: false,
      viewMode: false,
      isLoading: false,
      isLoading2: false,
      reload: false,
      dialog: false,
      toEdit: null,
      dialogRole: false,
      toEditRole: null
    }
  },
  watch: {
    'user.role_id': {
      handler(val) {
        let inx = this.roles.findIndex((m) => val == m.pk)
        if (inx > -1) {
          this.role = this.roles[inx]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      me: 'session/me'
    }),
    creationOrEditing() {
      return this.edit || this.validation === 'create'
    },
    viewUserBtn() {
      return {
        editUser: this.getPermissions([`users.change_profile`]) || this.isAdmin,
        addRol: this.getPermissions([`auth.add_group`]) || this.isAdmin,
        editRol: this.getPermissions([`auth.change_group`]) || this.isAdmin,
        viewRol: this.getPermissions([`auth.view_group`]) || this.isAdmin,
        editPermission:
          this.getPermissions([`auth.change_permission`]) || this.isAdmin,
        viewPermission:
          this.getPermissions([`auth.view_permission`]) || this.isAdmin
      }
    }
  },
  methods: {
    /**
     * reset
     * Método para resetear todos los datos del formulario en caso de crear
     * un usuario nuevo.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    reset() {
      this.user = {
        id: '',
        is_active: true,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        role_id: this.roleParam,
        groups: [],
        user_permissions: [],
        profile: {
          photo: '',
          phone: '',
          web: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip_code: '',
          token_forgot: null,
          identification: ''
        }
      }
      this.permissions = []
    },
    open() {
      this.dialog = true
    },
    openRoleModal(item) {
      this.toEditRole = item === undefined ? null : item
      this.dialogRole = true
    },
    async getRoles() {
      const rol = await this.$api.user.group({})
      this.roles = rol.data
    },
    async getAllPermissions() {
      const permission = await this.$api.user.listPermissions({})
      this.permissions_list = permission.data
    },
    activateEdit() {
      this.edit = !this.edit
    },
    /**
     * editFromList
     * Método que activa el modo edición de inmediato si viene desde el botón
     * editar en la lista de usuarios.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    editFromList() {
      if (this.$route.params.edit) {
        this.edit = this.$route.params.edit
      }
    },
    async getUserData() {
      this.loading = true
      this.validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (this.validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
        this.reset()
      } else {
        this.$api.user
          .show({ pk: this.validation })
          .then((res) => {
            this.user = res.data
            this.imageUrl = this.user.profile.photo
            if (this.roleParam) {
              this.user.groups = [{ pk: this.roleParam }]
            }
            this.$route.meta.title = this.$t('edit', { model: '' })
          })
          .catch(() => this.$router.push({ name: 'users' }))
      }
    },
    async submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.load = true
          let form_data = new FormData()
          for (let key in this.user) {
            if (key == 'profile') {
              let profile = JSON.parse(JSON.stringify(this.user[key]))
              if (
                profile.photo == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(profile.photo).indexOf(';base64') != -1 ||
                String(profile.photo).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1
              ) {
                delete profile.photo
              }
              for (let key2 in profile) {
                form_data.append(
                  'profile.' + key2,
                  key2 !== 'photo' ? profile[key2] : this.user.profile[key2]
                )
              }
            } else if (key == 'groups' || key == 'user_permissions') {
              form_data.append(key, JSON.stringify(this.user[key]))
            } else {
              form_data.append(key, this.user[key])
            }
          }
          this.isLoading2 = true
          if (this.user.id === '' || this.user.id === undefined) {
            this.$api.user
              .create({ form: form_data })
              .then((response) => {
                if (response.status == 201) {
                  this.$toast.success(
                    `${this.$tc('user', 1)} ${this.$tc('created', 2)}`
                  )
                  let dataEdited = Object.assign(this.user)
                  dataEdited.profile.photo = this.imageUrl
                  if (!this.roleParam) this.$router.push({ name: 'users' })
                  else this.$emit('done', this.user)
                }
              })
              .catch((error) => {
                console.error(error.response)
              })
              .finally(() => {
                this.isLoading2 = false
              })
          } else {
            this.$api.user
              .edit({
                pk: this.user.id,
                form: form_data
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast.success(
                    `${this.$tc('user', 1)} ${this.$tc('edited', 2)}`
                  )
                  let dataEdited = Object.assign(this.user)
                  dataEdited.profile.photo = this.imageUrl
                  this.$router.push({ name: 'users' })
                }
              })
              .catch((error) => {
                console.error(error.response)
              })
              .finally(() => {
                this.isLoading2 = false
              })
          }
        }
        this.load = false
      })
    },
    async getRoleParam() {
      try {
        const { data } = await this.$api.user.group({})
        this.user.groups.push(data.find((m) => m.name === 'Production'))
      } catch (error) {
        console.error('Error fetching role parameter:', error)
      }
    }
  },
  mounted() {
    if (this.roleParam) {
      this.getRoleParam()
    } else {
      this.getUserData()
    }
    this.getRoles()
    this.getAllPermissions()
    this.editFromList()
  }
}
</script>
<style lang="sass">
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections
  min-height: initial !important
</style>
